<template>
  <div class="table-container">
    <table class="table table-custom table-borderless table-custom-responsive mb-0">
      <thead>
        <tr>
          <th scope="col">Nombre</th>
          <th scope="col">N° Pantallas</th>
          <th scope="col">Estado</th>
          <th scope="col">Última modificación</th>
          <th scope="col" class="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody v-show="canales.length != 0">
        <tr v-for="(c, i) in canales" :key="i">
          <td class="th th-nombre">
            {{ c.nombre_canal }}
          </td>
          <td class="th th-numero-pantallas">
            {{ c.cantidad_pantallas }}
          </td>
          <td class="th th-estado">
            <span 
              v-if="c.estado.nombre_estado === 'Activo'"
              class="badge badge-custom badge-custom-green-light d-block">
              {{ c.estado.nombre_estado }}
            </span>
            <span 
              v-if="c.estado.nombre_estado === 'Inactivo'"
              class="badge badge-custom badge-custom-red-light d-block">
              {{ c.estado.nombre_estado }}
            </span>
            <span 
              v-if="c.estado.nombre_estado === 'Sin contenido'"
              class="badge badge-custom badge-custom-gray-light d-block">
              {{ c.estado.nombre_estado }}
            </span>

<!--        <div class="canales-table__estado-container">
              <div
                class="canales-table__circle"
                :style="{ 'background-color': `${c.estado!= null?c.estado.color: '0'}` }"
              ></div>
              <div class="ps-2">{{c.estado!= null?c.estado.nombre_estado: '0' }}</div>
            </div> -->
          </td>
          <td class="th th-ultima-modificacion">
            {{ dateFormatymd(c.fecha_modificacion) }}
          </td>
          <td class="th th-accion text-center">
            <div class="dropdown dropdown-custom d-flex justify-content-center">
                <button 
                  @click="openOption(c.id_canal, c)"
                  class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                  type="button"
                  data-bs-toggle="dropdown">
                  <font-awesome-icon icon="ellipsis-vertical"/>
                </button>
                <ul 
                  class="dropdown-menu"
                  v-click-outside="outOption">
                  <li v-show="c.estado.nombre_estado != 'Sin contenido'">
                    <a 
                      @click="editChannelContent(c.id_canal)"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                      Editar contenido
                    </a>
                  </li>
                  <li v-show="c.estado.nombre_estado === 'Sin contenido'">
                    <a 
                      @click="addContent(c.id_canal)"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-main" icon="upload"/>
                      Cargar contenido
                    </a>
                  </li>
                  <li v-show="c.estado.nombre_estado === 'Activo'">
                    <a 
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-main" icon="pause"/>
                      Pausar transmisión
                    </a>
                  </li>
                  <li v-show="c.estado.nombre_estado === 'En pausa'">
                    <a 
                      @click="playRequest"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-main" icon="play"/>
                      Reproducir
                    </a>
                  </li>
                  <li>
                    <a 
                      @click="editChannelConfig(c.id_canal)"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-secondary" icon="gear"/>
                      Configurar
                    </a>
                  </li>
                  <li>
                    <a 
                      @click="deleteChannel(c.id_canal)"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                      Eliminar
                    </a>
                  </li>
                </ul>
            </div>

<!--             <div class="pantallas-table__actions">
              <img
                @click="openOption(c.id_canal, c)"
                class="pantallas-table__dots"
                src="@/assets/img/qiyab.png"
                height="15"
                alt=""
              />
              <div
                class="options-encuesta"
                v-click-outside="outOption"
                v-show="option == c.id_canal"
              >
                <div
                  v-show="c.estado.nombre_estado != 'Sin contenido'"
                  @click="editChannelContent(c.id_canal)"
                  class="options-encuesta__option ps-2"
                >
                  <img
                    class="inscripciones-busqueda__option-img"
                    src="@/assets/img/Editar.svg"
                    alt=""
                  />
                  <div class="options-encuesta__option-text">Editar contenido</div>
                </div>
                <div
                  v-show="c.estado.nombre_estado == 'Sin contenido'"
                  @click="addContent(c.id_canal)"
                  class="options-encuesta__option ps-2"
                >
                  <img
                    class="inscripciones-busqueda__option-img"
                    src="@/assets/img/Subida.svg"
                    alt=""
                  />
                  <div class="options-encuesta__option-text">
                    Cargar contenido
                  </div>
                </div>
                <div
                  @click="editChannelConfig(c.id_canal)"
                  class="options-encuesta__option ps-2"
                >
                  <img
                    class="inscripciones-busqueda__option-img"
                    src="@/assets/img/conf_icon.svg"
                    alt=""
                  />
                  <div class="options-encuesta__option-text">Configuración</div>
                </div>
                <div
                  @click="duplicateChannel"
                  class="options-encuesta__option ps-2"
                >
                  <img
                    class="inscripciones-busqueda__option-img"
                    src="@/assets/img/duplicar.svg"
                    alt=""
                  />
                  <div class="options-encuesta__option-text">Duplicar</div>
                </div>
                <div
                  v-show="c.estado.nombre_estado == 'Activo'"
                  class="options-encuesta__option ps-2"
                >
                  <img
                    class="inscripciones-busqueda__option-img"
                    src="@/assets/img/pausa_contenido.svg"
                    alt=""
                  />
                  <div class="options-encuesta__option-text">
                    Pausar transmisión
                  </div>
                </div>
                <div
                  v-show="c.estado.nombre_estado == 'En pausa'"
                  @click="playRequest"
                  class="options-encuesta__option ps-2"
                >
                  <img
                    class="inscripciones-busqueda__option-img"
                    src="@/assets/img/reproducir.svg"
                    alt=""
                  />
                  <div class="options-encuesta__option-text">Reproducir</div>
                </div>
                <div
                  @click="deleteChannel(c.id_canal)"
                  class="options-encuesta__option ps-2"
                >
                  <img
                    class="inscripciones-busqueda__option-img"
                    src="@/assets/img/Eliminar.svg"
                    alt=""
                  />
                  <div class="options-encuesta__option-text">Eliminar</div>
                </div>
              </div>
            </div> -->
          </td>
        </tr>
      </tbody>
    </table>
    <div v-show="canales.length == 0" class="pantallas-table__nodata-container">
      <img
        src="@/assets/img/question_face.svg"
        alt=""
        class="pantallas-table__nodata-img"
      />
      <p class="pantallas-table__nodata-text">
        Aún no has creado un canal. Haz clic en el siguiente botón para
        comenzar.
      </p>
      <router-link
        :to="{ name: 'CanalCorporativo-add-canales' }"
        class="pantallas-table__nodata-button"
        >+ Crear canal</router-link
      >
    </div>
  </div>
</template>
 
 <script>
export default {
  props: {
    canales: {
      type: Array,
    },
  },
  data() {
    return {
      option: "-1",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario : this.$ls.get("user").id_usuario
    };
  },
  methods: {
    openOption(i) {
      if (this.option == i) {
        this.option = -1;
      } else {
        setTimeout(() => {
          this.option = i;
        }, 10);
      }
    },
    outOption() {
      if (this.option != -1) {
        this.option = -1;
      }
    },
    deleteChannel(id) {
    let data_ = {
    id_canal : id,
    id_empresa : this.id_empresa,
    id_usuario : this.id_usuario
    }
      this.$emit("deleteChannel", data_);
    },
    playRequest() {
      this.$emit("playRequest");
    },
    editChannelContent(id_) {
      this.$router.push({
        name: "CanalCorporativo-editContenido",
        params: {
          id: id_,
        },
      });
    },
    editChannelConfig(id_) {
      this.$router.push({
        name: "CanalCorporativo-edit-canales",
        params: {
          id: id_,
        },
      });
    },
    addContent(id_) {
      this.$router.push({
        name: "CanalCorporativo-addContenido",
        params: {
          id: id_,
        },
      });
    },
    duplicateChannel() {
      this.$emit("duplicateChannel");
    },
  },
};
</script>
 

<style lang="scss" scoped>
.pantallas-table__container {
  height: 100%;
}
</style>

<template>

  <section id="canales-list">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <i class="fa-solid fa-circle-play color-secondary"></i>
          Conoce los canales que has creado en tu Mural Digital.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          v-show="GetListCanales.length != 0"
          :to="{ name: 'CanalCorporativo-add-canales' }"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear canal
        </router-link>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 col-lg-4">
        <v-select
          class="selvue-custom"
          :options="GetEstadoCanalesFiltro"
          :reduce="estados => estados.id_estado"
          label="nombre_estado"
          placeholder="Filtrar por estado"
          :clearable="false"
          @input="filterByStatus"
          v-model="selectedEstadoCanales"
        ><span slot="no-options"></span>
        </v-select>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <Spinner v-if="show_spinner"/>
        <CanalesTable
          :canales="GetListCanales"
          @duplicateChannel="duplicateChannel"
          @playRequest="playRequest"
          @deleteChannel="deleteChannel"
        />
      </div>
    </div>

    <Question
      v-if="question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelDelete"
      @accept="acceptDelete"
    />

<!--     <section class="home-pantallas">
      <h2 class="organigrama-section__title">
        Conoce los canales que has creado en tu Mural Digital.
      </h2>
      <div class="home-pantallas__title-container">
        <div class="d-flex align-items-center">
          <h2 class="home-pantallas__title mb-0">Listado de canales</h2>
          <div class="home-canales__select-container">
            <v-select
              :options="GetEstadoCanalesFiltro"
              :reduce="estados => estados.id_estado"
              label="nombre_estado"
              placeholder="Filtrar por estado"
              :clearable="false"
              @input="filterByStatus"
              v-model="selectedEstadoCanales"
              ><span slot="no-options"></span
            ></v-select>
          </div>
        </div>
        <router-link
          v-show="GetListCanales.length != 0"
          :to="{ name: 'CanalCorporativo-add-canales' }"
          class="home-pantallas__add-button"
          >+ Crear canal</router-link
        >
      </div>
      <div class="home-pantallas__table-container">
        <Spinner v-if="show_spinner" />
        <CanalesTable
          :canales="GetListCanales"
          @duplicateChannel="duplicateChannel"
          @playRequest="playRequest"
          @deleteChannel="deleteChannel"
        />
        <div class="organigrama-section__pagination">
          <paginate
            v-model="pagination.actual_page"
            :page-count="pagination.page_count"
            :page-range="pagination.page_range"
            :click-handler="pagination.click_handler"
            :prev-text="pagination.prev_text"
            :next-text="pagination.next_text"
            :container-class="pagination.container_class"
            :page-class="pagination.page_class"
          >
          </paginate>
        </div>
      </div>
      <Question
        v-if="question_modal"
        :msg="question_modal_msg"
        :hideCancel="false"
        @cancel="cancelDelete"
        @accept="acceptDelete"
      />
    </section> -->
  </section>

</template>
 
 <script>
import { mapState, mapActions } from "vuex";
import CanalesTable from "./CanalesTable";
import Spinner from "../Spinner.vue";
import Question from "../Modales/Question.vue";
export default {
  components: {
    CanalesTable,
    Spinner,
    Question,
  },
  data() {
    return {
      filter_status: ["Activo", "Inactivo", "Sin contenido"],
      show_spinner: false,
      question_modal: false,
      question_modal_msg: "",
      estado: "-1",
      selectedEstadoCanales: "-1",
      dataDelete: {},
      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
    };
  },
  created(){
    this.getCanalesCorp();
    this.getEstado();
  },
  computed: {
    ...mapState("canalCorporativoModule", ["GetListCanales", "GetEstadoCanalesFiltro"]),
  },
  methods: {
  ...mapActions("canalCorporativoModule", ["getCanales", "deleteCanal", "getEstadoCanales"]),
    
    async getCanalesCorp(){
    let data_ = {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_estado: this.selectedEstadoCanales
    }
      await this.getCanales(data_);
      
    },
    async getEstado(){
      await this.getEstadoCanales();
      //console.log(this.GetEstadoCanalesFiltro);
    },

    async acceptDelete() {
      this.question_modal = false;
      this.show_spinner = true;
      //console.log("DATA A ELIMINAR", this.dataDelete);
      await this.deleteCanal(this.dataDelete).then(res=>{
          //console.log("RESULTADO LUEGO DE ELIMINAR: ", res);
          if (!res){
            this.show_spinner = false;
            this.$toast.open({
              message: "Falla al intentar eliminar el canal seleccionado",
              type: "error",
              duration: 0,
              position: "top-right",
            });
          }
          else {
              if(res.id_canal == null || res.id_canal == 0){
                  let mensaje_resultado = res.nombre_canal == null ? "Falla el proceso de eliminación" : res.nombre_canal;
                  this.show_spinner = false;
                  this.$toast.open({
                  message: mensaje_resultado,
                  type: "error",
                  duration: 6000,
                  position: "top-right",
                });
              }
              else {
                this.show_spinner = false;
                this.$toast.open({
                  message: "Tu canal ha sido eliminado correctamente",
                  type: "success",
                  duration: 6000,
                  position: "top-right",
                });
              }
          }
      });

    },
    cancelDelete() {
      this.question_modal = false;
    },
    deleteChannel(data_) {
      this.question_modal = true;
      this.question_modal_msg =
        "Al confirmar esta acción, dejará de reproducirse el contenido, ¿Deseas continuar?";
      this.dataDelete = data_;
      
    },
    playRequest() {
      this.show_spinner = true;
      setTimeout(() => {
        this.show_spinner = false;
        this.$toast.open({
          message: "Tu pantalla se esta reproduciendo correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
      }, 2000);
    },
    duplicateChannel() {
      this.show_spinner = true;
      setTimeout(() => {
        this.show_spinner = false;
        this.$toast.open({
          message: "Tu canal se ha duplicado correctamente",
          type: "success",
          duration: 3000,
          position: "top-right",
        });
      }, 2000);
    },
    filterByStatus() {
      console.log(this.selectedEstadoCanales);
      this.show_spinner = true;
      this.getCanalesCorp();
      this.show_spinner = false;
      /*setTimeout(() => {
        this.show_spinner = false;
      }, 1000);*/
    },
  },
};
</script>

